(function($){

// ---------- APP START
window.App = {

  config: {
    scrollOffset: 0
  },

  init: function(){
    // Init SVG polyfill
    if(typeof(svg4everybody) !== 'undefined') svg4everybody();

    // Init object-fill polyfill
    if(typeof(objectFitImages) !== 'undefined') objectFitImages();

    // Remove focus state when clicking on a button with a mouse
    $(':not(form) button, a').on('click', function(e){
      if(e.clientX && e.clientY)
        $(this).blur();
    });

    $('a[href^="#"]').on('click', function(e){
      e.preventDefault();

      var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');

      if($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
        App.scroll(scrollTo, $(this).data('scroll-offset'));
    });

    if($('[data-scroll]').length){
      App.scroll($('[data-scroll]').data('scroll'));
    }

    if($('.dropdown-btn').length) App.initDropdownButton();
  },

  initDropdownButton: function() {
    $(".dropdown-btn").click(function() {
      $('#'+$(this).data('dropdown')).toggleClass("is-hidden");
     });
  },

  load: function(){
  },

  scroll: function(element, offset){
    offset = offset === undefined ? App.config.scrollOffset : offset;

    $('html, body').animate({ scrollTop: $(element).offset().top - offset}, 'slow');
  },

};
// ---------- APP END

// Init app
$(document).ready(App.init);
$(window).on('load', App.load);

})(jQuery);
